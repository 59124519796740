import { useContext } from 'react';
import { AuthContext } from '../context/JWTContext';

/**
 * Custom hook to access the authentication context
 * @returns {object} The authentication context object
 * @throws {Error} If the authentication context is not found
 */
const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;
