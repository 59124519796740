import React, { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  return (
    <Suspense
      key={location.key}
      // fallback={<div className="text-black">Loading...</div>}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      children: [
        { element: <LoginPage />, index: true },
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          path: 'login',
        },
        {
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
          path: 'register',
        },
      ],
      path: 'auth',
      element: <Layout noPadding={true} />,
    },
    // Main Routes
    {
      children: [
        { element: <NotFound />, path: '404' },
        {
          element: <MainHome />,
          index: true,
        },
        {
          element: <Whishlist />,
          path: 'whishlist',
        },
        {
          path: 'profile',
          children: [
            { index: true, element: <Profile /> },
            { path: 'order', element: <OrderProfile /> },
          ],
        },
        {
          path: 'profile',
          children: [
            { index: true, element: <Profile /> },
            { path: 'order-admin', element: <OrderAdminProfile /> },
          ],
        },
        {
          path: 'lives',
          element: <LivePage />,
        },
        {
          path: 'brands',
          element: <BrandPage />,
        },
        {
          path: 'categories',
          element: <CategoryPage />,
        },
      ],
      element: <Layout />,
      path: '',
    },
    {
      children: [
        { element: <Cart />, index: true },
        { path: 'shipping', element: <ShippingForm /> },
      ],
      path: 'cart',
    },
    {
      children: [
        {
          element: <Product />,
          path: ':id',
        },
      ],
      path: 'live',
    },
    {
      children: [
        {
          element: <Product />,
          path: ':id',
        },
      ],
      path: 'promo',
    },
    {
      children: [
        {
          element: <Category />,
          path: ':id',
        },
      ],
      path: 'by-type',
    },
    {
      children: [
        {
          element: <Category />,
          path: ':id',
        },
      ],
      path: 'category',
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const MainHome = Loadable(lazy(() => import('../pages/home/index')));
const Whishlist = Loadable(lazy(() => import('../pages/whishlist/index')));
const LoginPage = Loadable(lazy(() => import('../pages/auth/index')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Profile = Loadable(lazy(() => import('../pages/auth/Profile')));
const OrderProfile = Loadable(lazy(() => import('../pages/auth/OrderProfile')));
const OrderAdminProfile = Loadable(
  lazy(() => import('../pages/auth/AdminOrder'))
);
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const Cart = Loadable(lazy(() => import('../pages/home/cart/index')));
const Product = Loadable(lazy(() => import('../pages/home/product/index')));
const LivePage = Loadable(lazy(() => import('../pages/home/product/LivePage')));
const BrandPage = Loadable(lazy(() => import('../pages/home/brands/index')));
const CategoryPage = Loadable(
  lazy(() => import('../pages/home/categories/index'))
);
// const ByType = Loadable(lazy(() => import('../pages/home/product/ByType')));
const Category = Loadable(
  lazy(() => import('../pages/home/product/FilterByCategory'))
);
const ShippingForm = Loadable(
  lazy(() => import('../pages/home/cart/ShippingForm'))
);
const Layout = Loadable(lazy(() => import('../layouts/main/Layout')));
const NotFound = Loadable(lazy(() => import('../pages/404/Page404')));
