import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { isValidToken, setSession } from '../utils/jwt';
import axiosInstance from '../utils/axios';
import { isMadagascarPhoneNumber } from '../utils/CheckPhoneNumber';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  /**
   * Initializes the state with authentication information and user data.
   * @param {object} state - The current state.
   * @param {object} action - The action object containing authentication and user data.
   * @returns {object} - The updated state.
   */
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  /**
   * Updates the state to indicate that the user has logged in.
   *
   * @param {object} state - The current state.
   * @param {object} action - The action object with the user payload.
   * @returns {object} - The updated state.
   */
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  /**
   * Logout the user
   * @param {Object} state - The current state
   * @returns {Object} - The updated state after logout
   */
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => ({
    ...state,
    isAuthenticated: true,
    user: action.payload.user,
  }),
};

/**
 * Reduces the state based on the provided action using the specified handlers.
 * If there is no handler for the action type, the state remains unchanged.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action to be performed on the state.
 * @returns {Object} - The new state after applying the action.
 */
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  forgot: () => Promise.resolve(),
});

/**
 * AuthProvider component that handles authentication logic
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The children of the component
 * @returns {React.ReactNode} - The rendered component
 */
function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const tokenData = JSON.parse(window.localStorage.getItem('accessToken'));
      const accessToken = tokenData?.accessToken;
      const user = tokenData?.user;

      if (accessToken && isValidToken(accessToken)) {
        setSession({ accessToken, user });

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  /**
   * Logs in the user with the provided email and password
   *
   * @param {string} email - The user's email
   * @param {string} password - The user's password
   */
  const login = useCallback(async (email, password) => {
    const response = await axiosInstance.post('/auth/login/', {
      email,
      password,
    });

    const { accessToken, user } = response.data;

    setSession({ accessToken, user });
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  const register = useCallback(async (email, password, firstName) => {
    let data = {
      password,
      firstName,
    };
    if (isMadagascarPhoneNumber(email)) {
      data.phone = email;
    } else {
      data.email = email;
    }
    console.log('🚀 ~ register ~ data:', data);
    const response = await axiosInstance.post('/auth/register', data);
    const { accessToken, user } = response.data;

    setSession({ accessToken, user });

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  /**
   * Sends a forgot password request for the provided email
   *
   * @param {string} email - The user's email
   */
  const forgot = async (email) => {
    const response = await axiosInstance.post('/auth/forgot/', {
      email,
    });
    console.log(response.data);
  };

  /**
   * Logs out the user
   */
  const logout = async () => {
    setSession({});
    dispatch({ type: 'LOGOUT' });
  };

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      forgot,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
