import Router from './routes';

import './App.css';
import 'react-day-picker/dist/style.css';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

function App() {
  return (
    <MotionLazyContainer>
      <Router />
    </MotionLazyContainer>
  );
}

export default App;
